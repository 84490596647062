import React from "react";
import AgentLogin from "../components/AgentLogin";

const WorkingHoursLogin = () => {
  return (
    <AgentLogin
      title="Accède aux heures de travail"
      description="Connectez-vous pour soumettre vos heures de travail."
      redirectRoute="/working-hours"
    />
  );
};

export default WorkingHoursLogin;
