import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Calendar from "react-calendar";
import Modal from "react-modal";
import axios from "../utils/axios";
import "react-calendar/dist/Calendar.css";
import "tailwindcss/tailwind.css";
import { format, parse, differenceInMinutes } from "date-fns";
import { fr } from "date-fns/locale";
import Config from "../utils/config";
import Spinner from "../components/Spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";

registerLocale("fr", fr);

const setToNoon = (date) => {
  const newDate = new Date(date);
  newDate.setHours(12, 0, 0, 0);
  return newDate;
};

const WorkingHoursCalendar = () => {
  const [value, setValue] = useState(new Date());
  const [workingHours, setWorkingHours] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventName, setEventName] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [eventComment, setEventComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [reminderVisible, setReminderVisible] = useState(false);
  const [unsavedDates, setUnsavedDates] = useState([]);

  const userInfo = {
    firstName: localStorage.getItem("firstName"),
    lastName: localStorage.getItem("lastName"),
    email: localStorage.getItem("email"),
  };

  const today = new Date();
  const currentMonth = today.getMonth();

  useEffect(() => {
    const fetchWorkingHours = async () => {
      try {
        const response = await axios.get(`${Config.baseURL}/working-hours/${userInfo.email}/${format(today, "yyyy-MM")}`);
        setWorkingHours(response.data.workingHours);
      } catch (error) {
        console.error("Error fetching working hours:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkingHours();
  }, [userInfo.email]);

  const handleDateClick = (date) => {
    const noonDate = setToNoon(date);
    setSelectedDate(noonDate);
    setModalIsOpen(true);

    const existingWork = workingHours.find(
      (work) => new Date(work.date).toDateString() === noonDate.toDateString()
    );

    if (existingWork) {
      setEventName(existingWork.eventName);
      setEventLocation(existingWork.eventLocation);
      setEventComment(existingWork.comment || "");
      setStartTime(parse(existingWork.startTime, "HH:mm", new Date()));
      setEndTime(parse(existingWork.endTime, "HH:mm", new Date()));
      if (!unsavedDates.includes(noonDate.toDateString())) {
        setUnsavedDates((prev) => [...prev, noonDate.toDateString()]);
      }
    } else {
      setEventName("");
      setEventLocation("");
      setEventComment("");
      setStartTime(null);
      setEndTime(null);
      setUnsavedDates((prev) => [...prev, noonDate.toDateString()]);
    }
  };

  const handleSaveWorkingHours = () => {
    if (!eventName || !eventLocation || !startTime || !endTime) {
      toast.error("Tous les champs (sauf le commentaire) doivent être remplis.");
      return;
    }

    if (startTime >= endTime) {
      toast.error("L'heure de fin doit être après l'heure de début.");
      return;
    }

    const updatedWorkingHours = workingHours.filter(
      (work) => new Date(work.date).toDateString() !== selectedDate.toDateString()
    );

    updatedWorkingHours.push({
      date: setToNoon(selectedDate).toISOString(),
      eventName,
      eventLocation,
      startTime: format(startTime, "HH:mm"),
      endTime: format(endTime, "HH:mm"),
      comment: eventComment
    });

    setWorkingHours(updatedWorkingHours);
    setUnsavedDates((prev) => [...prev, selectedDate.toDateString()]);
    setModalIsOpen(false);
    setReminderVisible(true);
  };

  const handleSubmitWorkingHours = async () => {
    try {
      await axios.post(`${Config.baseURL}/working-hours`, {
        email: userInfo.email,
        month: format(today, "yyyy-MM"),
        workingHours: workingHours.map((work) => ({
          ...work,
          date: setToNoon(new Date(work.date)).toISOString(),
        })),
      });
      toast.success("Les heures de travail ont été sauvegardées.");
      setReminderVisible(false);
      setUnsavedDates([]);
    } catch (error) {
      console.error("Error submitting working hours:", error);
      toast.error("Une erreur s'est produite lors de la sauvegarde des heures de travail.");
    }
  };

  const calculateDailyHours = (work) => {
    const date = work.date.split("T")[0];

    const start = parse(`${date} ${work.startTime}`, "yyyy-MM-dd HH:mm", new Date());
    const end = parse(`${date} ${work.endTime}`, "yyyy-MM-dd HH:mm", new Date());

    return differenceInMinutes(end, start);
  };

  const calculateTotalWorkingHours = () => {
    return workingHours.reduce((total, work) => total + calculateDailyHours(work), 0);
  };

  const totalWorkingHours = calculateTotalWorkingHours();

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      if (date.getMonth() !== currentMonth) {
        return true;
      }

      const existingWork = workingHours.find(
        (work) => new Date(work.date).toDateString() === date.toDateString()
      );
      if (existingWork && !unsavedDates.includes(date.toDateString())) {
        return true;
      }
    }
    return false;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 w-full bg-gray-100">
      <div className="w-full max-w-4xl bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
        <img src="/logo.png" alt="Logo" className="w-24 h-24 mb-4" />
        <h2 className="text-3xl font-bold mb-4 text-gray-700 text-center">
          Heures de travail pour {userInfo.firstName} {userInfo.lastName}
        </h2>
        {reminderVisible && (
          <div className="bg-yellow-100 mb-10 border-l-4 border-yellow-500 text-yellow-700 p-4 mt-4 w-full max-w-3xl rounded-lg">
            <p>N'oubliez pas de cliquer sur 'Valider' pour sauvegarder définitivement les heures de travail. Une fois sauvegardées, vous ne pourrez plus les modifier, assurez-vous qu'elles sont correctes.</p>
          </div>
        )}
        <Calendar
          onClickDay={handleDateClick}
          value={value}
          tileClassName={({ date }) => {
            const workForDate = workingHours.find(
              (work) =>
                new Date(work.date).toDateString() === setToNoon(date).toDateString()
            );
            return workForDate ? "bg-blue-500 text-white" : null;
          }}
          tileDisabled={tileDisabled}
          activeStartDate={new Date(today.getFullYear(), currentMonth, 1)}
          locale="fr"
          className="w-full max-w-3xl mb-8 rounded-lg shadow-lg"
        />

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-4"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <h2 className="text-xl font-bold mb-4 text-gray-700">
            Détails pour {selectedDate && format(selectedDate, "PPPP", { locale: fr })}
          </h2>
          <label className="block mb-2 text-gray-600">Nom de l'événement</label>
          <input
            type="text"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <label className="block mb-2 text-gray-600">Lieu de l'événement</label>
          <input
            type="text"
            value={eventLocation}
            onChange={(e) => setEventLocation(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <label className="block mb-2 text-gray-600">Commentaire (facultatif)</label>
          <textarea
            value={eventComment}
            onChange={(e) => setEventComment(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Ajoutez un commentaire sur cet événement"
          />
          <label className="block mb-2 text-gray-600">Heure de début</label>
          <DatePicker
            selected={startTime}
            onChange={(date) => setStartTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeFormat="HH:mm"
            timeCaption="Heure"
            dateFormat="HH:mm"
            locale="fr"
            className="w-full p-3 border border-gray-300 rounded-lg mb-4"
            placeholderText="Sélectionnez l'heure de début"
          />
          <label className="block mb-2 text-gray-600">Heure de fin</label>
          <DatePicker
            selected={endTime}
            onChange={(date) => setEndTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeFormat="HH:mm"
            timeCaption="Heure"
            dateFormat="HH:mm"
            locale="fr"
            className="w-full p-3 border border-gray-300 rounded-lg mb-4"
            placeholderText="Sélectionnez l'heure de fin"
          />
          <div className="flex justify-end mt-4">
            <button
              onClick={handleSaveWorkingHours}
              className="bg-blue-500 text-white py-2 px-6 rounded-lg mr-2 hover:bg-blue-600 transition duration-200"
            >
              Enregistrer
            </button>
            <button
              onClick={() => setModalIsOpen(false)}
              className="bg-gray-300 text-gray-700 py-2 px-6 rounded-lg hover:bg-gray-400 transition duration-200"
            >
              Annuler
            </button>
          </div>
        </Modal>

        <h3 className="text-xl font-bold mt-6 mb-2 text-gray-700">Événements enregistrés</h3>
        <ul className="mb-4 w-full max-w-3xl">
          {workingHours.map((work) => (
            <li key={work.date} className="mb-2 p-3 bg-gray-100 rounded-lg shadow-md">
              <span className="font-semibold text-gray-800">
                {format(new Date(work.date), "PPPP", { locale: fr })}:
              </span> {work.eventName} à {work.eventLocation} de {work.startTime} à {work.endTime}
              {work.comment && (
                <span className="block text-gray-700 mt-1 font-italic">
                  Commentaire : {work.comment}
                </span>
              )}
              <span className="block text-gray-600 mt-1">
                Total heures: {Math.floor(calculateDailyHours(work) / 60)} heures et {calculateDailyHours(work) % 60} minutes
              </span>
            </li>
          ))}
        </ul>

        <div className="text-lg font-semibold text-gray-800 mt-4">
          Total des heures travaillées: {Math.floor(totalWorkingHours / 60)} heures et {totalWorkingHours % 60} minutes
        </div>

        <button
          onClick={handleSubmitWorkingHours}
          className="bg-green-600 text-xl text-white py-3 px-8 rounded-lg hover:bg-green-700 transition duration-200 w-full max-w-xs mt-6"
        >
          Valider
        </button>
      </div>
      <ToastContainer />
      <div className="w-full flex justify-center items-center mt-10">
        <span className="text-lg text-gray-600 text-center">
          {userInfo.firstName} {userInfo.lastName} - Heures de travail
        </span>
      </div>
    </div>
  );
};

export default WorkingHoursCalendar;
