import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

 import HomePage from "./pages/HomePage";
import AgentPrivateRoutes from "./utils/AgentPrivateRoutes";
import DisponibilityAgent from "./pages/DisponibilityAgent";
import WorkingHoursLogin from "./pages/WorkingHoursLogin";
import DisponibilityLogin from "./pages/DisponibilityLogin";
import WorkingHoursCalendar from "./pages/WorkingHoursCalendar";


function App() {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
}

function MainLayout() {
 

  return (
    <div className="flex">
  
        <Routes>
          <Route element={<AgentPrivateRoutes />}>
            <Route element={<DisponibilityAgent />} path="/disponibility" />
            <Route element={<WorkingHoursCalendar />} path="/working-hours" />
          </Route>

          <Route element={<DisponibilityLogin />} path="/dispo-agent-login" />
          <Route element={<WorkingHoursLogin />} path="/working-hours-login" />
          <Route element={<HomePage />} path="/" />

          </Routes>
      </div>
   );
}

export default App;
