import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaClock } from 'react-icons/fa';

const HomePage = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = (route) => {
    navigate(route);
  };

  return (
    <div className="min-h-screen w-full bg-gradient-to-r from-blue-500 via-blue-400 to-blue-500 flex items-center justify-center px-4">
      <div className="bg-white shadow-2xl rounded-xl p-10 w-full max-w-3xl mx-auto">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-extrabold text-gray-800">
            Bienvenue sur le portail E.I.S.
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            Choisissez l'une des options ci-dessous pour continuer.
          </p>
        </div>
        
        <div className="space-y-6">
          <button
            onClick={() => handleLoginRedirect('/dispo-agent-login')}
            className="w-full flex items-center justify-center px-6 py-4 bg-blue-600 text-white text-xl font-semibold rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            aria-label="Se connecter pour remplir la disponibilité de l'agent"
          >
            <FaUser className="mr-3 text-2xl" />
            Remplir la disponibilité de l'agent
          </button>

          <button
            onClick={() => handleLoginRedirect('/working-hours-login')}
            className="w-full flex items-center justify-center px-6 py-4 bg-green-600 text-white text-xl font-semibold rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300"
            aria-label="Se connecter pour remplir les heures de travail de l'agent"
          >
            <FaClock className="mr-3 text-2xl" />
            Remplir les heures de travail de l'agent
          </button>
        </div>

        <div className="mt-12 text-sm text-gray-600">
          <hr className="border-t-2 border-gray-300 mb-4" />
          <p className="mb-2">
            Formulaire de recrutement S.A.R.L E.I.S. Les informations portées sur ce formulaire sont obligatoires. Elles font l’objet d’un traitement informatisé destiné au recrutement du personnel. Les destinataires des données sont : E.I.S.
          </p>
          <p>
            Depuis la loi "Informatique et Libertés" du 6 janvier 1978 modifiée, vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent. Si vous souhaitez exercer ce droit et obtenir communication des informations vous concernant, veuillez vous adresser à E.I.S.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
