import React from "react";
import AgentLogin from "../components/AgentLogin";

const DisponibilityLogin = () => {
  return (
    <AgentLogin
      title="Accède à la disponibilité"
      description="Connectez-vous pour gérer votre disponibilité."
      redirectRoute="/disponibility"
    />
  );
};

export default DisponibilityLogin;
