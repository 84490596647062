import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import Modal from "react-modal";
 import "react-calendar/dist/Calendar.css";
import "tailwindcss/tailwind.css";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import Config from "../utils/config";
import Spinner from "../components/Spinner";
import axios from "../utils/axios";

const setToNoon = (date) => {
  const newDate = new Date(date);
  newDate.setHours(12, 0, 0, 0);
  return newDate;
};

const AvailabilityCalendar = () => {
  const [value, setValue] = useState(new Date());
  const [availability, setAvailability] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isAvailable, setIsAvailable] = useState(false);
  const [dayAvailable, setDayAvailable] = useState(false);
  const [nightAvailable, setNightAvailable] = useState(false);
  const [isFilled, setIsFilled] = useState(null);
  const [loading, setLoading] = useState(true);

  const userInfo = {
    firstName: localStorage.getItem("firstName"),
    lastName: localStorage.getItem("lastName"),
    matricule: localStorage.getItem("matricule"),
    email: localStorage.getItem("email"),
    poste: localStorage.getItem("poste"),
  };

   const nextMonth = new Date();
  if (nextMonth.getDate() >= 15) {
    nextMonth.setMonth(nextMonth.getMonth() + 1);
  } 
  const nextMonthStr = `${nextMonth.getFullYear()}-${String(
    nextMonth.getMonth() + 1
  ).padStart(2, "0")}`;
  

  useEffect(() => {
 
    const fetchAvailability = async () => {
      try {
        const response = await axios.get(
          `${Config.baseURL}/agents-availability/${userInfo.email}/${nextMonthStr}`
        );
        setAvailability(response.data.availability || []);
        setIsFilled(response.data.hasAvailability);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching availability:", error);
      }
    };

    fetchAvailability();
  }, [userInfo.email, nextMonthStr]);

  const handleDateClick = (date) => {
    const noonDate = setToNoon(date);
    setSelectedDate(noonDate);
    setModalIsOpen(true);
    const existingAvailability = availability.find(
      (day) => new Date(day.date).toDateString() === noonDate.toDateString()
    );
    setIsAvailable(!!existingAvailability);
    setDayAvailable(existingAvailability ? existingAvailability.day : false);
    setNightAvailable(existingAvailability ? existingAvailability.night : false);
  };

  const handleSaveAvailability = () => {
    if (isAvailable && !dayAvailable && !nightAvailable) {
      alert("Vous devez sélectionner au moins une option: Jour ou Nuit.");
      return;
    }

    const updatedAvailability = availability.filter(
      (day) => new Date(day.date).toDateString() !== selectedDate.toDateString()
    );

    if (isAvailable) {
      updatedAvailability.push({
        date: setToNoon(selectedDate).toISOString(),
        isAvailable,
        day: dayAvailable,
        night: nightAvailable,
      });
    }

    setAvailability(updatedAvailability);
    setModalIsOpen(false);
  };

  const handleSubmitAvailability = () => {
    console.log(availability);
    axios
      .post(`${Config.baseURL}/availability`, {
        email: userInfo.email,
        month: nextMonthStr,
        availability: availability.map((day) => ({
          ...day,
          date: setToNoon(new Date(day.date)).toISOString(),
        })),
      })
      .then((response) => {
        setIsFilled(true);
      })
      .catch((error) => {
        console.error("Error submitting availability:", error);
        alert("Une erreur s'est produite lors de la sauvegarde des disponibilités.");
      });
  };

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      const currentMonth = nextMonth.getMonth();
      return date.getMonth() !== currentMonth;
    }
    return false;
  };

  if (loading) {
    return <Spinner />;
  }
  if (isFilled) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4 w-full">
        <div className="w-full max-w-6xl bg-white p-6 rounded-lg flex flex-col items-center shadow-lg">
          <img src="/logo.png" alt="Logo" className="w-24 h-24 mb-4" />
          <h2 className="sm:text-3xl text-xl text-center mb-4 text-gray-500">
            Merci pour ces disponibilités, nous reviendrons vers vous avec un planning qui vous sera envoyé par mail. En cas de changements dans vos disponibilité, vous pouvez vous reconnecter à tout moment sur ce lien et les modifier. <br></br>Merci et à très bientôt. <br></br>L’équipe EIS
          </h2>
          <button
            onClick={() => setIsFilled(false)}
            className="bg-blue-500 text-white py-2 px-6 mt-10 rounded-full shadow-md transition duration-300 ease-in-out transform hover:bg-blue-600 hover:shadow-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-300"
          >
            Modifier
          </button>
        </div>
      </div>
    );
  }
  
  

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 w-full bg-gray-100">
      <div className="w-full max-w-4xl bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
        <img src="/logo.png" alt="Logo" className="w-24 h-24 mb-4" />
        <div className="w-full flex justify-between  p-4 bg-white ">
          <div className="text-left">
            <h2 className="text-3xl font-bold mb-4 text-gray-500">
              Disponibilité Pour :
            </h2>
            <p className="text-lg mb-2">
              <strong>Nom:</strong> {userInfo.firstName} {userInfo.lastName}
            </p>
            <p className="text-lg mb-2">
              <strong>Poste:</strong> {userInfo.poste}
            </p>
            <p className="text-lg mb-2">
              <strong>Email:</strong> {userInfo.email}
            </p>
            <p className="text-lg mb-4">
              <strong>Matricule:</strong> {userInfo.matricule}
            </p>
            <p className="text-md mt-10 text-gray-600">
              Merci de sélectionner les jours sur lesquels vous êtes disponibles.
            </p>
          </div>
        </div>

        <Calendar
          onClickDay={handleDateClick}
          value={value}
          tileClassName={({ date }) => {
            const availabilityForDate = availability.find(
              (day) =>
                new Date(day.date).toDateString() ===
                setToNoon(date).toDateString()
            );
            return availabilityForDate ? "bg-green-500 text-white" : null;
          }}
          tileDisabled={tileDisabled}
          activeStartDate={
            new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1)
          }
          locale="fr"
          className="w-full"
        />

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto my-20"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <h2 className="text-xl font-bold mb-4">
            Disponibilité pour{" "}
            {selectedDate && format(selectedDate, "PPPP", { locale: fr })}
          </h2>
          <label className="block mb-2">
            <input
              type="checkbox"
              checked={isAvailable}
              onChange={() => setIsAvailable(!isAvailable)}
              className="mr-2"
            />
            Je suis disponible
          </label>
          {isAvailable && (
            <>
              <label className="block mb-2">
                <input
                  type="checkbox"
                  checked={dayAvailable}
                  onChange={() => setDayAvailable(!dayAvailable)}
                  className="mr-2"
                />
                Jour
              </label>
              <label className="block mb-2">
                <input
                  type="checkbox"
                  checked={nightAvailable}
                  onChange={() => setNightAvailable(!nightAvailable)}
                  className="mr-2"
                />
                Nuit
              </label>
            </>
          )}
          <div className="flex justify-end mt-4">
            <button
              onClick={handleSaveAvailability}
              className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
            >
              Enregistrer
            </button>
            <button
              onClick={() => setModalIsOpen(false)}
              className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
            >
              Annuler
            </button>
          </div>
        </Modal>

        <h3 className="text-xl font-bold mt-6 mb-2">Jours sélectionnés</h3>
        <ul className="mb-4">
          {availability
            .filter((day) => day.isAvailable)
            .map((day) => (
              <li key={day.date} className="mb-2 flex items-center">
                {format(new Date(day.date), "PPPP", { locale: fr })} -{" "}
                <div className="flex ml-2">
                  {day.day && (
                    <span className="bg-blue-500 text-white px-2 py-1 rounded-full mr-2">
                      Jour
                    </span>
                  )}
                  {day.night && (
                    <span className="bg-purple-500 text-white px-2 py-1 rounded-full">
                      Nuit
                    </span>
                  )}
                </div>
              </li>
            ))}
        </ul>

        <button
          onClick={handleSubmitAvailability}
          className="bg-green-600 text-xl text-white py-2 w-full px-4 rounded"
        >
          Valider
        </button>
      </div>
      <div className="mt-4 max-w-xl text-center">
        <p>
          Merci de compléter ce formulaire. Votre coopération est essentielle
          pour assurer une organisation optimale et une répartition équitable
          des missions.
        </p>
        <p>L'équipe de EIS</p>
      </div>
    </div>
  );
};

export default AvailabilityCalendar;
